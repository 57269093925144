import React from "react"
import { useLocation } from "@reach/router"
import { Header, Head, Footer } from "@components/layout"

const Layout = ({ children, siteMetaData, seo, imBusy, translationUrl }) => {
  const location = useLocation()
  let defaultTranslation = {
    pl: "/pl",
    en: "/en",
  }
  if (translationUrl) {
    defaultTranslation = translationUrl
  }
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Head seo={seo} siteMetaData={siteMetaData} location={location} />
      <Header translationUrl={defaultTranslation} />

      <main style={{ marginTop: "100px" }}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
