import React, { useCallback, useRef } from "react"
import { AnimatedLink, AnimatedSection } from "@components/shared"
import { INavItem, IMenu, IClickableDiv } from "./types"

import {
  menu__open,
  menu__closed,
  menu__nav,
  menu__nav__medias,
  menu__nav__medias__icon,
  menu__nav__list__item,
} from "./header.module.scss"
import useClickOutside from "../../standard/Home/common/useClickOutside"
import Instagram from "../../standard/Home/icons/Animated/Instagram"
import Fb from "../../standard/Home/icons/Animated/Fb"
import Yt from "../../standard/Home/icons/Animated/Yt"
const navLinks: Array<INavItem> = [
  {
    name: "Sklep",
    url: "/",
  },
  {
    name: "Autorzy",
    url: "/",
  },
  {
    name: "Blog",
    url: "/blog",
  },
  {
    name: "Kontakt",
    url: "/kontakt",
  },
]

// uniwersalny
const ClickableDiv: React.FC<IClickableDiv> = (props) => <div {...props} />

const Menu: React.FC<IMenu> = ({ isMenuOpen, toggleMenu, closeMenu }) => {
  const handleLinkClick = useCallback(
    (e) => {
      if (e.target.hasAttribute("href")) toggleMenu()
    },
    [isMenuOpen]
  )

  return (
    <ClickableDiv
      className={isMenuOpen ? menu__open : menu__closed}
      onClick={handleLinkClick}
    >
      <div className={menu__nav}>
        <ul>
          {navLinks.map(({ name, url, button }) => {
            return (
              <li key={name}>
                <AnimatedLink to={url} className={menu__nav__list__item}>
                  {name}
                </AnimatedLink>
              </li>
            )
          })}
        </ul>
        <div className={menu__nav__medias}>
          <a
            href="https://www.facebook.com/The-Old-Hemp-102812905028116"
            className={menu__nav__medias__icon}
            target="_blank"
          >
            <Fb />
          </a>
        </div>
      </div>
    </ClickableDiv>
  )
}

export default Menu
