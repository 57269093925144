import React, { useState, useEffect, useCallback, useContext } from "react"
import { useActions, LangCtx, useContextState } from "@components/contexted"
import { Link } from "gatsby"
import {
  changer__wrapper,
  changer__wrapper__disabled,
} from "./header.module.scss"
import en from "./icons/eng.svg"
import pl from "./icons/pl.svg"

const LanguageChanger: React.FC = ({ translationUrl }): JSX.Element => {
  const { language } = useContextState(LangCtx, ["language"])
  const { setLangPl, setLangEn } = useActions(LangCtx, [
    "setLangPl",
    "setLangEn",
  ])
  return (
    <div className={changer__wrapper}>
      <div className={language === "pl" ? changer__wrapper__disabled : ""}>
        <Link
          onClick={() => {
            setLangPl()
            localStorage.setItem("lang", "pl")
          }}
          to={translationUrl.pl}
        >
          <img src={pl}></img>
        </Link>
      </div>
      <div className={language === "en" ? changer__wrapper__disabled : ""}>
        <Link
          to={translationUrl.en}
          onClick={() => {
            setLangEn()
            localStorage.setItem("lang", "en")
          }}
        >
          <img src={en}></img>
        </Link>
      </div>
    </div>
  )
}

export default LanguageChanger
