import React from "react"
import { useActions, LangCtx, useContextState } from "@components/contexted"
import { useLocation } from "@reach/router"
import {
  footer,
  footer__container,
  footer__container__wrapper,
  footer__container__links,
  footer__container__links__link,
  footer__container__image,
  footer__container__image__icons,
  footer__container__image__icons__icon,
} from "./footer.module.scss"
import Logo from "../../standard/Home/icons/theoldhempLOGOtext.svg"

import { AnimatedLink } from "@components/shared"
import Yt from "../../standard/Home/icons/Animated/Yt"
import Instagram from "../../standard/Home/icons/Animated/Instagram"
import Fb from "../../standard/Home/icons/Animated/Fb"
const Footer: React.FC = (): JSX.Element => {
  const { pathname } = useLocation()
  const { language } = useContextState(LangCtx, ["language"])

  return (
    <footer className={footer}>
      <div className={footer__container}>
        <div className={footer__container__image}>
          <img src={Logo} alt="" />
          <div className={footer__container__image__icons}>
            <AnimatedLink className={footer__container__image__icons__icon}>
              <Fb />
            </AnimatedLink>
            {/* <AnimatedLink className={footer__container__image__icons__icon}>
              <Instagram />
            </AnimatedLink>
            <AnimatedLink className={footer__container__image__icons__icon}>
              <Yt />
            </AnimatedLink> */}
          </div>
        </div>
        <div className={footer__container__wrapper}>
          <div className={footer__container__links}>
            <AnimatedLink to="/" className={footer__container__links__link}>
              {translation[language].oils}
            </AnimatedLink>
            <AnimatedLink to="/blog" className={footer__container__links__link}>
              Blog
            </AnimatedLink>
            <AnimatedLink
              to="/kontakt"
              className={footer__container__links__link}
            >
              {translation[language].contact}
            </AnimatedLink>
            <AnimatedLink
              className={footer__container__links__link}
              to="/regulaminy"
            >
              {translation[language].regulations}
            </AnimatedLink>
          </div>
          <div className={footer__container__links}>
            <a
              href="https://www.facebook.com/The-Old-Hemp-102812905028116"
              target="_blank"
              className={footer__container__links__link}
            >
              Facebook
            </a>
            {/* <AnimatedLink className={footer__container__links__link}>
              Twitter
            </AnimatedLink>
            <AnimatedLink className={footer__container__links__link}>
              Instagram
            </AnimatedLink>
            <AnimatedLink className={footer__container__links__link}>
              Youtube
            </AnimatedLink> */}
          </div>
        </div>
        <div className={footer__container__links}>
          <div className={footer__container__links__link}>
            Cannable Sp. z o.o.
          </div>
          <div className={footer__container__links__link}>
            ul. Dolna 3 Maja 1/1A 20-079 Lublin
          </div>

          <div className={footer__container__links__link}>
            <a href="tel:+48535000959">(+48) 535 000 959</a>
          </div>

          <div className={footer__container__links__link}>
            <a href="mailto:contact@theoldhemp.com">contact@theoldhemp.com</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const translation = {
  pl: {
    oils: "Nasze oleje",
    contact: "Kontakt",
    regulations: "Regulaminy",
  },
  en: {
    oils: "Our oils",
    contact: "Contact",
    regulations: "Regulations",
  },
}
