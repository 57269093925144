import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import { useSpring } from "react-spring/"
import { InteractWithUser } from "@components/logic"
// import { useDelayedRedirect } from "@components/layout"

const animationStates = {
  idle: 1,
  hover: 1.05,
  clicking: 1.1,
  clicked: 1,
}

const AnimatedLink = (props) => {
  const { children } = props

  // const redirect = useDelayedRedirect({ to, onClick })

  const [mode, setter] = useState(false)
  const { scale } = useSpring({
    config: { mass: 1, velocity: 0.5 },
    scale: animationStates[mode] || 1,
  })

  const animationSwitch = useCallback(setter, [])

  return (
    <InteractWithUser
      onChangePhase={animationSwitch}
      style={{
        transform: scale.interpolate((s) => `scale(${s})`),
      }}
    >
      <Link {...props}>{children}</Link>
    </InteractWithUser>
  )
}

export default AnimatedLink
