import React, { useState, useEffect, useCallback, useContext } from "react"
import { useActions, LangCtx, useContextState } from "@components/contexted"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { WindowCtx } from "@components/contexted"
import { INavItem } from "./types"
import Menu from "./Menu"
import { AnimatedLink } from "@components/shared"
import LanguageChanger from "./LanguageChanger"
import Hamburger from "./Hamburger"
import Instagram from "../../standard/Home/icons/Animated/Instagram"
import Fb from "../../standard/Home/icons/Animated/Fb"
import Yt from "../../standard/Home/icons/Animated/Yt"
import Leaf from "../../standard/Home/icons/logo.svg"
import { useLocation } from "@reach/router"
import {
  header,
  nav,
  nav__container,
  nav__container__logo,
  nav__container__medias,
  nav__container__medias__left,
  nav__container__links,
  nav__container__link,
  bottom,
  logo,
  nav__container__medias__left__icon,
} from "./header.module.scss"
import { animated, useSpring } from "react-spring"

const translatedLinks = [
  {
    pl: {
      name: "Nasze oleje",
      url: "/pl",
    },
    en: {
      name: "Our oils",
      url: "/en",
    },
  },
  {
    pl: {
      name: "Blog",
      url: "/pl/blog/",
    },
    en: {
      name: "Blog",
      url: "/en/blog/",
    },
  },
  {
    pl: {
      name: "Kontakt",
      url: "/pl/contact",
    },
    en: {
      name: "Contact",
      url: "/en/contact",
    },
  },
]

const navLinks: Array<INavItem> = [
  {
    name: "Nasze oleje",
    url: "/",
  },
  {
    name: "Blog",
    url: "/blog",
  },
  {
    name: "Kontakt",
    url: "/kontakt",
  },
]

const Header: React.FC = ({ translationUrl }): JSX.Element => {
  const { y } = useContext(WindowCtx)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()
  const { language } = useContextState(LangCtx, ["language"])
  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const handleKeydown = useCallback((e) => {
    if (!isMenuOpen) return
    if (e.which === 27 || e.key === "Escape") toggleMenu()
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", (e: Event) => handleKeydown(e))
    return () =>
      window.removeEventListener("keydown", (e: Event) => handleKeydown(e))
  }, [])

  return (
    <header className={header}>
      <Helmet>
        <body className={isMenuOpen ? "blocked" : ""} />
      </Helmet>

      <nav className={nav}>
        <div className={nav__container}>
          <div className={nav__container__logo}>
            <Link to={`/${language}`}>
              <img src={Leaf}></img>
            </Link>
          </div>
          <ul className={nav__container__links}>
            {translatedLinks.map((el, i) => (
              <li key={i}>
                <AnimatedLink
                  to={el[language].url}
                  className={
                    location.pathname === el[language].url ||
                    (location.pathname.includes("/blog/") &&
                      el[language].url === "/blog")
                      ? `${nav__container__link} ${bottom}`
                      : nav__container__link
                  }
                >
                  {el[language].name}
                </AnimatedLink>
              </li>
            ))}
          </ul>
          <div className={nav__container__medias}>
            <a
              href="https://www.facebook.com/The-Old-Hemp-102812905028116"
              target="_blank"
              className={nav__container__medias__left__icon}
            >
              <Fb />
            </a>
            <LanguageChanger translationUrl={translationUrl} />
          </div>
        </div>
        <div className={logo}>
          <Link to={"/"}>
            <img src={Leaf}></img>
          </Link>
        </div>
      </nav>
      <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <Menu
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        closeMenu={() => setIsMenuOpen(false)}
      />
    </header>
  )
}

export default Header
